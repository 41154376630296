/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-04-02",
    versionChannel: "nightly",
    buildDate: "2024-04-02T00:05:30.599Z",
    commitHash: "501b42c63f011ab53593291aa1fc3a7e4538b4b5",
};
